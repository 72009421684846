import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { formatDrugEvent } from '@sancare/ui-frontend-commons/src/misc/DrugEventHelper'
import { HealthDataDocument, HealthDataType, TextualHealthEntryType } from '@sancare/ui-frontend-commons/src/types/health-data'
import { Dayjs } from 'dayjs'

import {
  BaseAct,
  BaseCodedAct,
  CodedMedicalAct,
  MedicalAct,
  RawCodedMedicalAct
} from '@/store/modules/medical-act/types'
import { Patient, RawPatient } from '@/store/modules/patient/types'
import { RawStay, Stay } from '@/store/modules/stay/types'

export function formatBaseStay(rawStay: RawStay): Stay {
  return {
    id: rawStay.id,
    administrativeLocalStayId: rawStay.administrativeLocalStayId,
    comment: rawStay.comment,
    drugEvents: (rawStay.drugEvents ?? []).map((event) => formatDrugEvent(event)),
    patient: formatPatient(rawStay.patient),
    patientAge: rawStay.patientAge,
    status: rawStay.status,
    stayDuration: rawStay.stayDuration,
    stayEnd: dayjs(rawStay.stayEnd),
    stayStart: dayjs(rawStay.stayStart),
    staySavedSearches: rawStay.staySavedSearches,
    namedEntities: rawStay.namedEntities,
    reports: (rawStay.reports ?? []).map((report) => ({ ...report, creationDate: report.creationDate ? dayjs(report.creationDate) : null })),
    textualHealthEntries: (rawStay.textualHealthEntries ?? []).map((entry) => ({ ...entry, creationDate: entry.creationDate ? dayjs(entry.creationDate) : null })),
    categoricalLabResults: (rawStay.categoricalLabResults ?? []).map((res) => ({ ...res, creationDate: res.creationDate ? dayjs(res.creationDate) : null })),
    labResults: (rawStay.labResults ?? []).map((res) => ({ ...res, creationDate: res.creationDate ? dayjs(res.creationDate) : null })),
    healthConstants: (rawStay.healthConstants ?? []).map((cst) => ({ ...cst, creationDate: cst.creationDate ? dayjs(cst.creationDate) : null })),
  }
}

function formatPatient(rawPatient: RawPatient): Patient {
  return {
    id: rawPatient.id,
    ipp: rawPatient.ipp,
    firstName: rawPatient.firstName,
    lastName: rawPatient.lastName,
    maidenName: rawPatient.maidenName,
    gender: rawPatient.gender,
    birthDate: dayjs(rawPatient.birthDate)
  }
}

export function formatCodedMedicalActs(rawActs: RawCodedMedicalAct[]): CodedMedicalAct[] {
  return rawActs.map((rawAct) => formatCodedMedicalAct(rawAct))
}

function formatCodedMedicalAct(rawAct: RawCodedMedicalAct): CodedMedicalAct {
  return {
    ...formatCodedAct<MedicalAct, RawCodedMedicalAct>(rawAct),
    extension: rawAct.extension,
    realisationCount: rawAct.realisationCount,
  }
}

function formatCodedAct
<TAct extends BaseAct, TRawCodedAct extends BaseCodedAct<string, TAct>>(rawAct: TRawCodedAct):
BaseCodedAct<Dayjs, TAct> {
  return {
    id: rawAct.id,
    associatedAct: rawAct.associatedAct,
    disabled: rawAct.disabled,
    executionDate: dayjs(rawAct.executionDate),
    practitioner: rawAct.practitioner
  }
}

export function selectMainContent(stay: RawStay) {
  const reports = stay.reports.filter((report) => report.content)
  const textualHealthEntries = stay.textualHealthEntries.filter((healthEntry) => healthEntry.content)
  if (reports.length) {
    return { type: HealthDataType.REPORT, targetId: reports[0].id }
  }
  if (stay.drugEvents.length) {
    return { type: HealthDataType.DRUG_EVENT }
  }
  if (stay.healthConstants.length) {
    return { type: HealthDataType.CONSTANT }
  }
  if (stay.labResults.length || stay.categoricalLabResults.length) {
    return { type: HealthDataType.LAB_RESULT }
  }
  if (textualHealthEntries.length) {
    if (textualHealthEntries.some((t) => t.type === TextualHealthEntryType.NURSE_REPORT)) {
      return { type: TextualHealthEntryType.NURSE_REPORT }
    }
    if (textualHealthEntries.some((t) => t.type === TextualHealthEntryType.MEDICAL_HISTORY)) {
      return { type: TextualHealthEntryType.MEDICAL_HISTORY }
    }
    if (textualHealthEntries.some((t) => t.type === TextualHealthEntryType.SURGERY_LABEL)) {
      return { type: TextualHealthEntryType.SURGERY_LABEL }
    }
    return { type: TextualHealthEntryType.LAB_RESULT_COMMENT }
  }
  return { type: null }
}

export function formatMainContent({ type, drugEventPrestationType = null, targetId = null }): HealthDataDocument {
  switch(type) {
    case HealthDataType.REPORT:
      return { type: HealthDataType.REPORT, drugEventPrestationType: null, targetId: targetId }
    case HealthDataType.LAB_RESULT:
    case HealthDataType.CAT_LAB_RESULT:
      return { type: HealthDataType.LAB_RESULT, drugEventPrestationType: null, targetId: null }
    case HealthDataType.CONSTANT:
      return { type: HealthDataType.CONSTANT, drugEventPrestationType: null, targetId: null }
    case HealthDataType.DRUG_EVENT:
      return {
        type: HealthDataType.DRUG_EVENT,
        drugEventPrestationType: drugEventPrestationType,
        targetId: null,
      }
    default:
      return { type, drugEventPrestationType: null, targetId: null }
  }
}
