<script>
import _ from 'lodash'

import {
  chunkIsSelected,
  getChunkClasses,
  getChunkOrigin,
  tagAsFalseJustification,
  tagAsLegitJustification,
  toggleFalseJustificationTypes
} from '../chunkJustifications'
import { getHealthDataLabel } from './documentLabels.js'

export default {
  props: {
    textualHealthEntries: { type: Array, required: true },
    displayedRumIdx: { type: Number, required: true },
    type: { type: String, required: true },
    selection: { type: Object, default: null },
    savedSearches: { type: Array, default: null },
    rumPredictedLabels: { type: Array, default: null },
    shouldScroll: { type: Boolean, required: true },
    isDocumentSource: { type: Boolean, required: false },
  },
  emits: ['disable-justification-scrolling'],
  data() {
    return {
      showFalseJustificationTypes: {},
    }
  },
  mounted() {
    if (this.shouldScroll) {
      this.$nextTick(this.scrollToChunk)
    }
  },
  beforeUpdate() {
    if (this.shouldScroll) {
      this.$nextTick(this.scrollToChunk)
    }
  },
  methods: {
    getHealthDataLabel(type) {
      return getHealthDataLabel(type)
    },
    getChunkClasses(chunk, selection) {
      return getChunkClasses(chunk, selection, this.displayedRumIdx)
    },
    tagAsFalseJustification({ report, healthEntry, chunk, errorType }) {
      const origin = getChunkOrigin(chunk, this.rumPredictedLabels, this.savedSearches, this.displayedRumIdx)
      this.showFalseJustificationTypes[chunk.hash] = tagAsFalseJustification(report, healthEntry, chunk, errorType, origin, this.$store)
    },
    tagAsLegitJustification({ report, healthEntry, chunk }) {
      this.showFalseJustificationTypes[chunk.hash] = tagAsLegitJustification(report, healthEntry, chunk, this.$store)
    },
    toggleFalseJustificationTypes(chunk) {
      toggleFalseJustificationTypes(chunk, this.showFalseJustificationTypes)
    },
    scrollToChunk() {
      let selectedChunk = null
      let hash = null
      _.forEach(this.textualHealthEntries, (healthEntry) => {
        if (this.type === healthEntry.type) {
          _.forEach(healthEntry.chunks, (chunk) => {
            if ((selectedChunk === null && chunk.isHighlighted) || chunkIsSelected(chunk, this.selection, this.displayedRumIdx)) {
              selectedChunk = chunk
              hash = parseInt(selectedChunk.hash)
            }
          })
        }

      })

      if (this.$refs[hash] && selectedChunk !== null) {
        const chunkPosition = window.pageYOffset + this.$refs[hash][0].getBoundingClientRect().top
        const margin = window.innerHeight/2
        scroll({ top: chunkPosition - margin, behavior: 'smooth' })
      }

      this.$emit('disable-justification-scrolling')

    },
  }
}
</script>

<template>
  <div :class="{'highlight-on-click' : isDocumentSource }">
    <div class="card-body">
      <h1 class="report-title">
        {{ getHealthDataLabel(type) }}
      </h1>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Contenu</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="healthEntry in textualHealthEntries"
            :key="healthEntry.id"
          >
            <td>{{ healthEntry.creationDate ? healthEntry.creationDate.format('L') : '-' }}</td>
            <td v-if="healthEntry.chunks">
              <span
                v-for="chunk in healthEntry.chunks"
                :key="chunk.hash"
                :ref="chunk.hash"
                :class="getChunkClasses(chunk, selection)"
                class="text-data-content"
                @click="toggleFalseJustificationTypes(chunk)"
              >{{ chunk.string }}<!--  Comments are here to avoid whitespace issues
              --><v-dropdown
                  v-if="(chunk.isHighlighted || chunk.isFalseJustification)"
                  :shown="showFalseJustificationTypes[chunk.hash]"
                  placement="bottom-start"
                  class="popover-container popover-container__invisible"
                  @auto-hide="() => showFalseJustificationTypes[chunk.hash] = false"
                ><!--
              --><template #popper>
                    <span
                      v-if="!chunk.isFalseJustification"
                    >
                      Signaler comme :
                      <div
                        v-for="signal in [['negation', 'Négation'], ['antecedent', 'Antécédent'], ['doubt', 'Passage incertain'], ['recurrence', 'Motif récurrent']]"
                        :key="signal[0]"
                      >
                        <button
                          class="btn btn-link btn-sm"
                          @click="tagAsFalseJustification({ errorType: signal[0], report : null, healthEntry: healthEntry, chunk })"
                        >
                          {{ signal[1] }}
                        </button>
                      </div>
                    </span>
                    <span
                      v-else
                    >
                      <button
                        class="btn btn-link btn-sm"
                        @click="tagAsLegitJustification({ report: null, healthEntry: healthEntry, chunk })"
                      >
                        Annuler le signalement
                      </button>
                    </span>
                  </template><!--
              --></v-dropdown><!--
            --></span>
            </td>
            <td v-else>
              <span class="text-data-content">{{ healthEntry.content }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
