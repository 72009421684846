import { MedicalUnit } from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter/types'
import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { Dayjs } from 'dayjs'

import {
  BaseAct,
  BaseCodedAct,
  CodedAct,
  CodedActChop,
  CodedMedicalAct,
  RawCodedAct
} from '@/store/modules/medical-act/types'
import { BaseSummaryUnit, GroupingError, RawStay, Stay } from '@/store/modules/stay/types'

export interface Drg {
  reference: string
  description: string
}

export interface Ghm {
  reference: string
  cmd?: string
  level: string
  description: string
  ghmType?: string
}

export interface BaseRum<
  TDate,
  TAct extends BaseAct,
  TCodedAct extends BaseCodedAct<TDate, TAct>>
  extends BaseSummaryUnit<TDate, TAct, TCodedAct> {
  id: number
  rumStart: TDate
  rumEnd: TDate
  admissionMode: string | null
  releaseMode: string | null
  origin: string | null
  destination: string | null
  standardModeDiagnosisGroup: any // eslint-disable-line @typescript-eslint/no-explicit-any
  newbornWeight: number | null
}
export type RawRum<TCodedAct extends RawCodedAct> = BaseRum<string, BaseAct, TCodedAct>
export type Rum<TCodedAct extends CodedAct> = BaseRum<Dayjs, BaseAct, TCodedAct>
export type RumCh = Rum<CodedActChop> & {
  artificialVentilationDuration: number | null
  pregnancyDuration: number | null
}
export type RumFr = Rum<CodedMedicalAct> & {
  medicalUnit: MedicalUnit
  standardModeGroupingErrors: GroupingError[]
  supervisingDoctorName?: string | null
}

export interface BaseMcoStay<TRum> {
  rssId: string
  rums: TRum[]
  rumCount: number
  firstStandardModeValue: number | null
  standardModeValue: number | null
  automationModeValue: number | null
  standardModeGroupingError: GroupingError | null
  automationModeGroupingError: GroupingError | null
  firstStandardModeGhm: Ghm | null
  standardModeGhm: Ghm | null
  automationModeGhm: Ghm | null
  firstStandardModeGhs: string | null
  standardModeGhs: string | null
  automationModeGhs: string | null
  // TODO: Move to McoStayCh
  firstStandardModeDrg: Drg | null
  // TODO: Move to McoStayCh
  standardModeDrg: Drg | null
  // automationModeDrg: Drg|null;
  firstStandardCflag: string | null
  standardCflag: string | null
  // automationCflag: number|null;
  firstStandardPccl: number | null
  standardPccl: number | null
  // automationPccl: number|null;
}
// TODO: Define TCodedAct (beware of future impacts)
export type RawMcoStay = RawStay & BaseMcoStay<RawRum<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
export type McoStay = Stay & BaseMcoStay<Rum<any>> // eslint-disable-line @typescript-eslint/no-explicit-any

// TODO: Use TRum instead
export type McoStayCh = McoStay & {
  rums: RumCh[]
}
// TODO: Use TRum instead
export type McoStayFr = McoStay & {
  rums: RumFr[]
}

export enum StayActionTypeEnum {
  AUTO_VALIDATE_STAY = 'auto_validate_stay',
  VALIDATE_STAY = 'validate_stay',
  UNVALIDATE_STAY = 'unvalidate_stay',
  MANUAL_SYNC_STAY = 'manual_sync_stay',
  ARCHIVE_STAY = 'archive_stay',
}

export const stayActionLabels = {
  [StayActionTypeEnum.AUTO_VALIDATE_STAY]: 'Validation auto',
  [StayActionTypeEnum.VALIDATE_STAY]: 'Validation manuelle',
  [StayActionTypeEnum.UNVALIDATE_STAY]: 'Invalidation',
  [StayActionTypeEnum.MANUAL_SYNC_STAY]: 'Synchronisation manuelle',
  [StayActionTypeEnum.ARCHIVE_STAY]: 'Archive',
}

export type StayAction = {
  id: number
  username: string
  type: StayActionTypeEnum
  creationDate: Dayjs
}
export type StayActionDtoIn = Omit<StayAction, 'creationDate'> & {
  creationDate: string
}

// to be completed
export class McoStayState extends AbstractState {
  currentActions = [] as StayAction[]
}
