import Api from '@/misc/api'
import { getUrlQuery } from '@/misc/string_utils'

const EMPTY_HOME_STATS = {
  countTotalGlobalStay:          0,
  countTodoGlobalStay:           0,
  countTotalAutomatableStay:     0,
  countTodoAutomatableStay:      0,
  countNewCodingAutomatableStay: 0,
  countNewCodingShortStay:       0,
  countNewCodingLongStay:        0,
  countTotalOptimizableStay:     0,
  countTodoDasRevaluableStay:    0,
  countTodoDpRevaluableStay:     0,
  countDasRevaluableStay:        0,
  countChronicDasRevaluableStay: 0,
  countDpRevaluableStay:         0,
  valueTotalAutomatableStay:     0,
  valueTodoAutomatableStay:      0,
}

export default {
  state: {
    homeStats: EMPTY_HOME_STATS,
    homeChartStats: [],

    getGlobalStatsRequest: { fetching: false, error: null, ok: false },
    getChartStatsRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    getHomeStatsStart(state) {
      state.getHomeStatsRequest = { fetching: true, error: null, ok: false }
    },
    getHomeStatsSuccess(state, stats) {
      state.getHomeStatsRequest.fetching = false
      state.getHomeStatsRequest.ok = true
      state.homeStats = stats
    },
    getHomeStatsError(state, res) {
      state.getHomeStatsRequest.fetching = false
      state.getHomeStatsRequest.error = res.message
      state.homeStats = EMPTY_HOME_STATS
    },
    getHomeChartStatsStart(state) {
      state.getHomeChartStatsRequest = { fetching: true, error: null, ok: false }
    },
    getHomeChartStatsSuccess(state, stats) {
      state.getHomeChartStatsRequest.fetching = false
      state.getHomeChartStatsRequest.ok = true
      state.homeChartStats = stats
    },
    getHomeChartStatsError(state, res) {
      state.getHomeChartStatsRequest.fetching = false
      state.getHomeChartStatsRequest.error = res.message
      state.homeChartStats = []
    },
  },
  actions: {
    getHomeStats({ commit }, urlArgs) {
      return Api.get(
        {
          url: `/api/home-stats?${getUrlQuery(urlArgs)}`,
          startMut: 'getHomeStatsStart',
          successMut: 'getHomeStatsSuccess',
          errorMut: 'getHomeStatsError',
        },
        commit
      )
    },
    getHomeChartStats({ commit }, urlArgs) {
      return Api.get(
        {
          url: `/api/home-chart-stats?${getUrlQuery(urlArgs)}`,
          startMut: 'getHomeChartStatsStart',
          successMut: 'getHomeChartStatsSuccess',
          errorMut: 'getHomeChartStatsError',
        },
        commit
      )
    },
  },
}
