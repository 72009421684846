import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'

import { Chunk } from '@/stay-displayer/health-data/types'
import { BaseAct } from '@/store/modules/medical-act/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { Search } from '@/store/modules/stay-saved-search/types'

export enum PredictedLabelType {
  DP = 'DP',
  DR = 'DR',
  DAS = 'DAS',
  MEDICAL_ACTS = 'medicalActs',
}

export interface PredictedLabel {
  id: number
  type: PredictedLabelType
  diagnosis: Diagnosis | null
  medicalAct: BaseAct | null
  confidence: number
  promoted: boolean
  disabled: boolean
  automationModeExcluded: boolean
  standardModeExcluded: boolean
  hints?: HintPredictedLabel[]
  chunks?: Chunk[]
}
export type PredictedDiagnosis = PredictedLabel & {
  diagnosis: Diagnosis
  medicalAct: null
}
export type PredictedMedicalAct = PredictedLabel & {
  diagnosis: null
  medicalAct: BaseAct
}

export enum DiagnosisType {
  DP = 'DP',
  DR = 'DR',
  FPP = 'FPP',
  MMP = 'MMP',
  AE = 'AE',
  DAS = 'DAS',
}
export function getSingleDiagnosisTypes(pmsiType: PmsiTypeEnum): DiagnosisType[] {
  switch (pmsiType) {
    case PmsiTypeEnum.MCO:
      return [
        DiagnosisType.DP,
        DiagnosisType.DR,
      ]
    case PmsiTypeEnum.SSR:
      return [
        DiagnosisType.FPP,
        DiagnosisType.MMP,
        DiagnosisType.AE,
      ]
    default:
      return [
        DiagnosisType.DP,
        DiagnosisType.DR,
        DiagnosisType.FPP,
        DiagnosisType.MMP,
        DiagnosisType.AE,
      ]
  }
}

export interface Diagnosis {
  id?: number
  reference: string
  type?: number | null
  description?: string
  diagnosisVersions?: DiagnosisVersion[]
}

export interface SuggestedDiagnosis extends Diagnosis {
  search?: Search
}

export interface DiagnosisVersion {
  id: number
  level: number
  // Used internally by textWorkerHelper, no need to define generic type for now
  startDate: string
  endDate: string
}

export interface HintPredictedLabel {
  id: number
  hintStart: number
  hintEnd: number
  confidence: number
  targetType: HealthDataType
  targetId: number
}

export class DiagnosisState extends AbstractState {}
