import { Store } from 'vuex'

import { RhsLoadingErrorReason, SsrLoadingErrorReason } from '@/store/modules/stay/ssr/types'
import { RootState } from '@/store/types'

export async function refreshStay(ssrId: number, store: Store<RootState>) {
  await store.dispatch('ssrStay/fetchStay', ssrId)
  const request = store.state.ssrStay.requests.fetchStay
  if (request.error) {
    throw new Error(request.error.status === 404 ? SsrLoadingErrorReason.SSR_STAY_NOT_FOUND : SsrLoadingErrorReason.SSR_STAY_OTHER)
  }
}

export async function refreshRhs(rhsId: number, store: Store<RootState>) {
  await store.dispatch('ssrStay/fetchRhs', rhsId)
  const request = store.state.ssrStay.requests.fetchRhs
  if (request.error) {
    throw new Error(request.error.status === 404 ? RhsLoadingErrorReason.RHS_NOT_FOUND : RhsLoadingErrorReason.RHS_OTHER)
  }
}
