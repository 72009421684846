import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { toRaw } from 'vue'

import { buildRssDisplay } from '@/stay-displayer/rssRumBuilder'

let runningWorker = null

const removeSearchDuplicata = (stay) => {
  const privateSearchReference = _.map(_.filter(stay.staySavedSearches, (ss) => ss.search.privacy === 'privateSearch'), (ss) => ss.search.reference)
  const staySavedSearches = []

  _.forEach(stay.staySavedSearches, (ss) => {
    if (ss.search.privacy === 'privateSearch') {
      staySavedSearches.push(ss)
    } else if (privateSearchReference.indexOf(ss.search.reference) === -1) {
      staySavedSearches.push(ss)
    }
  })

  return { ...stay, staySavedSearches: staySavedSearches }
}

// This is a recursive function that returns the same object,
// but where diagnoses (object that have a key named "diagnosis") are changed
// in order to include the level of the current stay.
const parseDiagnosisLabels = (stayEnd, obj) => {
  if (_.isArray(obj)) {
    return _.map(obj, parseDiagnosisLabels.bind(this, stayEnd))
  }
  if (_.isObjectLike(obj)) {
    return _.mapValues(obj, (value, key) => {
      if (!value || !key) {
        return value
      }
      if (_.includes(key.toLowerCase(), 'diag') && _.isArray(value) && _.every(_.map(value, (itm) => _.isArray(itm.diagnosisVersions)))) {
        return _.map(value, (subvalue) => {
          const correctVersion = _.find(subvalue.diagnosisVersions, (ver) => dayjs(ver.startDate).isSameOrBefore(stayEnd) && dayjs(ver.endDate).isAfter(stayEnd))
          return {
            ...subvalue,
            level: correctVersion ? correctVersion.level : 1
          }
        })
      }
      if (_.includes(key.toLowerCase(), 'diag') && _.isArray(value.diagnosisVersions)) {
        const correctVersion = _.find(value.diagnosisVersions, (ver) => dayjs(ver.startDate).isSameOrBefore(stayEnd) && dayjs(ver.endDate).isAfter(stayEnd))
        return {
          ...value,
          level: correctVersion ? correctVersion.level : 1
        }
      }
      return parseDiagnosisLabels(stayEnd, value)
    })
  }
  return obj
}

const simpleParseStay = (stay) => {
  const stayEnd = dayjs(stay.stayEnd)
  const staySavedSearches = _.filter(stay.staySavedSearches, (s)=> !s.disabled)
  stay = {
    ...stay,
    patient: {
      ...stay.patient,
      birthDate: stay.patient.birthDate ? dayjs(stay.patient.birthDate) : null,
    },
    suggestedChronicDas: stay.suggestedChronicDas,
    lastPredictionUpdate: dayjs(stay.lastPredictionUpdate),
    lastHealthDataUpdate: dayjs(stay.lastHealthDataUpdate),
    lastExternalPmsiUpdate: dayjs(stay.lastExternalPmsiUpdate),
    stayStart: dayjs(stay.stayStart),
    stayEnd,
    reports: _.map(stay.reports, (report) => ({
      ...report,
      creationDate: report.creationDate ? dayjs(report.creationDate) : null,
    })),
    categoricalLabResults: _.map(stay.categoricalLabResults, (res) => ({
      ...res,
      creationDate: res.creationDate ? dayjs(res.creationDate) : null,
    })),
    labResults: _.map(stay.labResults, (res) => ({
      ...res,
      creationDate: res.creationDate ? dayjs(res.creationDate) : null,
    })),
    drugEvents: _.map(stay.drugEvents, (event) => ({
      ...event,
      creationDate: event.creationDate ? dayjs(event.creationDate) : null,
    })),
    textualHealthEntries: _.map(stay.textualHealthEntries, (entry) => ({
      ...entry,
      creationDate: entry.creationDate ? dayjs(entry.creationDate) : null,
    })),
    healthConstants: _.map(stay.healthConstants, (cst) => ({
      ...cst,
      creationDate: cst.creationDate ? dayjs(cst.creationDate) : null,
    })),
    rums: _.map(stay.rums, (rum) => ({
      ...parseDiagnosisLabels(stayEnd, rum),
      rumStart: dayjs(rum.rumStart),
      rumEnd: dayjs(rum.rumEnd),
    })),
    staySavedSearches: _.map(staySavedSearches, (staySavedSearch) => ({
      ...parseDiagnosisLabels(stayEnd, staySavedSearch),
    })),
    savedSearchIntersectionChunks: stay.savedSearchIntersectionChunks || [],
    additionnalKeywordSearches: stay.additionnalKeywordSearches || [],
    additionnalKeywordChunks: stay.additionnalKeywordChunks || {},
    isLoading: ('isLoading' in stay) ? stay.isLoading : true,
  }

  if (stay.rums.length > 1) {
    const rssDisplayRum = buildRssDisplay(stay.rums)
    stay.rums.push(rssDisplayRum)
  }

  return stay
}

const keywordList = (criteriaGroups) => {
  const keywordList = []
  let presentContentCriteria = []
  _.forEach(criteriaGroups, (criteriaGroup) => {
    const currentPresentContentCriteria = _.filter(criteriaGroup.criteriaList, (f) => f.type.startsWith('presentContent'))
    if (currentPresentContentCriteria) {
      presentContentCriteria = _.uniq(_.concat(presentContentCriteria, currentPresentContentCriteria))
    }
  })

  _.forEach(presentContentCriteria, (criterion) => {
    const typeTokens = criterion.type.split('__')
    const docType = typeTokens.length > 1 ? typeTokens[1] : null
    const key = docType !== null ? `kw-${docType}` : 'kw'

    if (!keywordList[key]) {
      keywordList[key] = []
    }
    keywordList[key].push(criterion.value)
  })

  return keywordList
}

function asyncStayParsing(stay, stayListState, settings) {
  if (runningWorker !== null) {
    runningWorker.terminate()
  }
  const promise = new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    runningWorker = new Worker(new URL('../text-worker.js', import.meta.url))

    runningWorker.onmessage = (e) => {
      resolve(simpleParseStay({ ...e.data, isLoading: false }))
      runningWorker.terminate()
      runningWorker = null
    }
    runningWorker.onerror = (e) => {
      runningWorker.terminate()
      runningWorker = null
      // eslint-disable-next-line no-console
      console.error(e)
      reject()
    }

    // toRaw is used because only simple objects can be parsed by worker postMessage
    const rawSettings = toRaw(settings)
    runningWorker.postMessage({ stay: removeSearchDuplicata(stay), keywordParam: keywordList(stayListState.currentSearch.criteriaGroups), settings: rawSettings })
  })

  // caution: sometimes text-worker crash silently. We must catch manually the error in order to show it.
  promise.catch((err) => {
    // eslint-disable-next-line
    console.error(err)
  })

  return promise
}

export {
  asyncStayParsing,
  removeSearchDuplicata,
  simpleParseStay
}
