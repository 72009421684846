<script>
import { mapState } from 'vuex'

import VolumeGraphics from './VolumeGraphics.vue'

export default {
  components: {
    'volume-graphics': VolumeGraphics,
  },
  props: {
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    chartUnit: { type: String, default: 'month' },
    selectedMedicalUnits: { type: Array, default: null },
    labelSets: { type: Array, required: true },
  },
  computed: {
    ...mapState({
      stats: (state) => state.stats.optimizableStaysStats,
      statsPercent(state) {
        return state.stats.optimizableStaysStats.map((s) => {
          return {
            prctRevaluableStay: s.countStay > 0 ? ((s.countDASRevaluableStay / s.countStay) * 100.00).toFixed(2) : 0,
            prctOpenedStay: s.countStay > 0 ? ((s.countOptimizableOpenedStay / s.countStay) * 100.00).toFixed(2) : 0,
            prctValidatedStay: s.countStay > 0 ? ((s.countOptimizableValidatedStay / s.countStay) * 100.00).toFixed(2) : 0,
            prctEditedStay: s.countStay > 0 ? ((s.countOptimizableEditedStay / s.countStay) * 100.00).toFixed(2) : 0,
          }
        })
      },
    }),
  },
  watch: {
    earlyLimit() {
      this.refresh()
    },
    lateLimit() {
      this.refresh()
    },
    chartUnit() {
      this.refresh()
    },
    selectedMedicalUnits() {
      this.refresh()
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch('getOptimizableStaysStats', {
        earlyLimit: this.earlyLimit.format('YYYY-MM-DD'),
        lateLimit: this.lateLimit.format('YYYY-MM-DD'),
        unit: this.chartUnit,
        medicalUnits: this.selectedMedicalUnits,
      })
    },
  }
}
</script>

<template>
  <div class="stat">
    <h1 class="stat-title">
      Revalorisation
    </h1>
    <volume-graphics
      :label-sets="labelSets"
      :stats="stats"
      :stats-percent="statsPercent"
      :prct-infos="{
        prctRevaluableStay: { label: 'Revalorisables (DAS)', unit: '%' },
        prctOpenedStay: { label: 'Consultés', unit: '%' },
        prctEditedStay: { label: 'Validés (avec modification)', unit: '%' },
        prctValidatedStay: { label: 'Validés (total)', unit: '%' },
      }"
      :stats-infos="{
        countStay: { label: 'Optimisables', unit: 'séjour(s)' },
        countDASRevaluableStay: { label: 'Revalorisables (DAS)', unit: 'séjour(s)'},
        countOptimizableOpenedStay: { label: 'Consultés', unit: 'séjour(s)' },
        countOptimizableValidatedStay: { label: 'Validés (total)', unit: 'séjour(s)' },
        countOptimizableEditedStay: { label: 'Revalorisés (avec modification)', unit: 'séjour(s)' },
      }"
    />
  </div>
</template>
