import _ from 'lodash'
import { Store } from 'vuex'

import { FalseJustificationOriginType } from '@/false-justifications/types'
import { ListedSavedSearch } from '@/saved-searches/types'
import { Chunk, Report, TextualHealthEntry } from '@/stay-displayer/health-data/types'
import { CodingSelection } from '@/stay-displayer/types'
import { PredictedLabel, PredictedLabelType } from '@/store/modules/diagnosis/types'
import { RootState } from '@/store/types'

type ChunkModifier = {
  chunkClass: string
  modifiers: {
    clickable: boolean
    highlighted: boolean
  }
}

type ChunkOrigin = {
  originType: FalseJustificationOriginType|null
  predictedLabelIds: number[]
  searches: string[]
}

function getChunkModifiers(chunk: Chunk, selection: CodingSelection, displayedRumIdx: number): ChunkModifier {
  let chunkClass = ''
  const modifiers = { clickable: false, highlighted:false }

  Object.values(PredictedLabelType).forEach((labelType) => {
    if (typeof chunk.predictionMatches !== 'undefined' && chunk.predictionMatches[displayedRumIdx] && chunk.predictionMatches[displayedRumIdx][labelType].length) {
      chunkClass = `health-data-hint-${labelType}`
      modifiers.clickable = true
      if (chunk.isFalseJustification) {
        chunkClass = 'health-data-reported'
      }
      if (selection && labelType === selection.labelType && chunk.predictionMatches[displayedRumIdx][labelType].includes(selection.labelReference)) {
        modifiers.highlighted = true
      }
    }
  })

  if (chunk.savedSearches.length) {
    if (chunk.isNegative) {
      chunkClass = 'health-data-negation'
      modifiers.clickable = false
    } else if (chunk.isDoubt) {
      chunkClass = 'health-data-doubt'
    } else if (chunk.isFalseJustification) {
      chunkClass = 'health-data-reported'
      modifiers.clickable = true
    } else {
      chunkClass = 'health-data-keyword'
      modifiers.clickable = true
    }
    if (selection && _.intersection(selection.savedSearchIds, chunk.savedSearches).length) {
      modifiers.highlighted = true
    }
  }

  return { chunkClass: chunkClass, modifiers: modifiers }
}

export function getChunkClasses(chunk: Chunk, selection: CodingSelection, displayedRumIdx: number): string[] {
  const addedClasses = []

  const classAndModifiers = getChunkModifiers(chunk, selection, displayedRumIdx)
  const modifiers = classAndModifiers.modifiers
  const chunkClass = classAndModifiers.chunkClass

  Object.keys(modifiers).map(function(key) {
    if (modifiers[key] === true) {
      addedClasses.push(key)
    }
  })

  return [chunkClass + (addedClasses.length ? `__${addedClasses.sort().join('-')}` : '')]
}

export function chunkIsSelected(chunk: Chunk, selection: CodingSelection, displayedRumIdx: number): boolean {
  const modifiers = getChunkModifiers(chunk, selection, displayedRumIdx).modifiers
  return modifiers.highlighted === true
}

export function getChunkOrigin(chunk: Chunk, summaryUnitPredictedLabels: PredictedLabel[], savedSearches: { search: ListedSavedSearch }[], displayedRumIdx: number): ChunkOrigin|null {
  let originType = null
  const searches = []
  const predictedLabelIds = []

  if (!summaryUnitPredictedLabels && !chunk.savedSearches) {
    return null
  }

  if (chunk.predictionMatches) {
    _.forEach(summaryUnitPredictedLabels, (predictedLabel) => {
      Object.values(PredictedLabelType).forEach((labelType) => {
        if (chunk.predictionMatches[displayedRumIdx][labelType].length) {
          _.map(chunk.predictionMatches[displayedRumIdx][labelType], (labelReference) => {
            const predictedReference = predictedLabel.diagnosis?.reference ?? predictedLabel.medicalAct.reference
            if (labelReference === predictedReference) {
              originType = FalseJustificationOriginType.PREDICTED_LABEL
              predictedLabelIds.push(predictedLabel.id)
            }
          })
        }
      })
    })
  }

  _.map(savedSearches, (s) => {
    if (chunk.savedSearches.includes(s.search.id)) {
      originType = originType === FalseJustificationOriginType.PREDICTED_LABEL ? FalseJustificationOriginType.BOTH : FalseJustificationOriginType.SEARCH_CRITERIA
      searches.push(s.search.reference)
    }
  })

  return {
    originType,
    predictedLabelIds,
    searches,
  }
}

export function tagAsFalseJustification(report: Report|null, healthEntry: TextualHealthEntry|null, chunk: Chunk, errorType: string, origin: ChunkOrigin, store: Store<RootState>): boolean {

  store.dispatch('createFalseJustification', {
    errorType,
    docType: report ? 'report' : 'textualHealthEntry',
    docId: report ? report.id : healthEntry.id,
    hintStart: chunk.chunkStart,
    hintEnd: chunk.chunkEnd,
    report: report,
    healthEntry: healthEntry,
    originType: origin.originType,
    diagnoses: origin.predictedLabelIds,
    searches: origin.searches
  })

  return false
}

export function tagAsLegitJustification(report: Report|null, healthEntry: TextualHealthEntry|null, chunk: Chunk, store: Store<RootState>): boolean {
  const currentDoc = report || healthEntry
  const justifications = _.filter(currentDoc.falseJustifications, (j) => j.hintStart === chunk.chunkStart && j.hintEnd === chunk.chunkEnd)
  _.forEach(justifications, (j) => {
    store.dispatch('deleteFalseJustification', {
      justificationId: j.id,
      reportId: report ? report.id : null,
      healthEntryId: healthEntry ? healthEntry.id : null,
    })
  })

  return false
}

export function toggleFalseJustificationTypes(chunk: Chunk, currentStatus: { [hash: string]: boolean }): void {
  if (chunk.isHighlighted) {
    currentStatus[chunk.hash] = !currentStatus[chunk.hash]
  }
}
