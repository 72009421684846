import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { Dayjs } from 'dayjs'

export interface BasePatient<TDate> {
  id: number
  ipp: string
  birthDate: TDate
  gender: string
  lastName: string
  maidenName: string
  firstName: string
}

export type RawPatient = BasePatient<string>

export type Patient = BasePatient<Dayjs>

export const defaultPatient: Patient = {
  id: 0,
  birthDate: dayjs(),
  firstName: 'Prénom',
  gender: 'H',
  ipp: '00000001',
  lastName: 'Nom',
  maidenName: ''
}
